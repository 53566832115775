import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api/api.service';
import { AddCourseRequestBodyI } from './interfaces/add-course-request-body.interface';
import { CourseI } from './interfaces/course.interface';
import { EditCourseRequestBodyI } from './interfaces/edit-course-request-body.interface';
import { FilterCoursesI } from './interfaces/filter-courses.interface';
import { DeleteCourseRequestQuery } from './interfaces/delete-course-request-query.interface';
import { CloI } from '../../shared/interfaces/clo.interface';
import { DeleteCloRequestQuery } from '../../shared/interfaces/delete-clo-request-query.interface';
import { EditCloRequestBodyI } from '../../shared/interfaces/edit-clo-request-body.interface';
import { AddCloRequestBodyI } from '../../shared/interfaces/add-clo-request-body.interface';
import { FilterCoursesForAcademicYearI } from './interfaces/filter-courses-for-academic-year.interface';
import { AolCourse } from './interfaces/aol-course.interface';
import { SemesterType } from '../academic-years/academic-year/enums/semester-type.enum';
import { CreateCorrectiveActionRequestBodyI } from './interfaces/create-corrective-action-request-body.interface';
import { CorrectiveActionI } from './interfaces/corrective-action.interface';

@Injectable({
  providedIn: 'root',
})
export class CoursesApiService {
  constructor(private readonly apiService: ApiService) {}

  findAllForFaculty(facultyID: string, filterCourses: FilterCoursesI) {
    return this.apiService.get<CourseI[]>(
      `courses/faculty/${facultyID}`,
      filterCourses
    );
  }

  findAllForDepartment(departmentID: string, filterCourses: FilterCoursesI) {
    return this.apiService.get<CourseI[]>(
      `courses/department/${departmentID}`,
      filterCourses
    );
  }

  findAllForProgram(programID: string, filterCourses: FilterCoursesI) {
    return this.apiService.get<CourseI[]>(
      `courses/program/${programID}`,
      filterCourses
    );
  }

  findAllForAcademicYear(
    academicYearID: string,
    filterCoursesForAcademicYear: FilterCoursesForAcademicYearI
  ) {
    return this.apiService.get<CourseI[]>(
      `courses/academic-year/${academicYearID}`,
      filterCoursesForAcademicYear
    );
  }

  findAll(filterCourses: FilterCoursesI) {
    return this.apiService.get<CourseI[]>('courses', filterCourses);
  }

  findOne(courseID: string) {
    return this.apiService.get<CourseI>(`courses/${courseID}`);
  }

  addCourse(addCourseRequestBody: AddCourseRequestBodyI, programID: string) {
    return this.apiService.post<CourseI>(
      `courses/${programID}`,
      addCourseRequestBody
    );
  }

  editCourse(courseID: string, editCourseRequestBody: EditCourseRequestBodyI) {
    return this.apiService.patch<CourseI>(
      `courses/${courseID}`,
      editCourseRequestBody
    );
  }

  deleteCourse(
    courseID: string,
    deleteCourseRequestQuery: DeleteCourseRequestQuery
  ) {
    return this.apiService.delete<CourseI>(
      `courses/${courseID}`,
      deleteCourseRequestQuery
    );
  }

  addClo(addCloRequestBody: AddCloRequestBodyI, courseID: string) {
    return this.apiService.post<CloI>(`clos/${courseID}`, addCloRequestBody);
  }

  editClo(cloID: string, editCloRequestBody: EditCloRequestBodyI) {
    return this.apiService.patch<CloI>(`clos/${cloID}`, editCloRequestBody);
  }

  deleteClo(cloID: string, deleteCloRequestQuery: DeleteCloRequestQuery) {
    return this.apiService.delete<CloI>(`clos/${cloID}`, deleteCloRequestQuery);
  }

  findAllClo(courseID: string) {
    return this.apiService.get<CloI[]>(`clos/course/${courseID}`);
  }

  count() {
    return this.apiService.get<number>(`courses/count`);
  }

  aol(courseID: string, academicYearID: string, semesterType: SemesterType) {
    return this.apiService.get<AolCourse>(`courses/${courseID}/aol/clo-plo`, {
      academicYearID,
      semesterType,
    });
  }

  findAllCorrectiveActions(courseID: string) {
    return this.apiService.get<CorrectiveActionI[]>(
      `corrective-actions/${courseID}`
    );
  }

  createCorrectiveAction(
    courseID: string,
    createCorrectiveActionRequestBody: CreateCorrectiveActionRequestBodyI
  ) {
    return this.apiService.post<CorrectiveActionI>(
      `corrective-actions/${courseID}`,
      createCorrectiveActionRequestBody
    );
  }
}
